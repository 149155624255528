export const environment = {
  production: true,
  apiURL: "https://prod-api.nationimagine.com",
  ieeeApiURL: "https://6rwbl65676.execute-api.ap-south-1.amazonaws.com",
  ieeeRequestURL: "blended-learning.ieee.org",
  courseProgressAPI: "https://blended-learning.ieee.org/GrokAPI/api/GrokprogressionAPI",
  domainUrl: "https://ide.nationimagine.com/",
  staticFilesUrl: "https://prod-static.nationimagine.com/",
  organizationID: "grokcloud",
  boardIdentifier: "32a6ec83-88a8-4f71-aab6-3353828b3896",
  applicationIdentifier: "4300a06f-5933-4b8a-b9c2-c5ee47acdce3",
  authURL: "https://auth.nationimagine.com",
  wsUrl: "wss://prod-ws.nationimagine.com",
  timestreamURL: "https://67onmpcgjw5eiyhzt65kvydkf40gphlr.lambda-url.us-east-1.on.aws/",
  pythonDirectUrl:"https://prod-pycode.nationimagine.com",
  gccApiURL: "https://r9ptmswyqg.execute-api.ap-south-1.amazonaws.com/prod/",
  gccDevFrontEndUrl: 'https://gic.grovator.com/login?from-grok=true',
  env: 'prod'
};
