import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output, SimpleChanges } from '@angular/core';
import { CircuitSectionModule } from 'src/app/admin/components/circuit-section/circuit-section.component';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/index.state";
import { KonvaUtils, CircuitLoadOptions } from "src/app/shared/utils/konva.utils";
import { CommonActions } from "src/app/shared/utils/helpers";
import { environment } from 'src/environments/environment';
import { IDEService } from 'src/app/shared/services/ide.service';
import { ToastrService } from 'ngx-toastr';
import { circuitDefinition } from 'src/app/shared/models/circuit';
import { UserSessionService } from 'src/app/shared/services/user.session.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';

@Component({
  selector: 'app-create-circuit',
  templateUrl: './create-circuit.component.html',
  styleUrls: ['./create-circuit.component.scss']
})
export class CreateCircuitComponent implements OnInit {

  @Input() ieeeEvent: any;
  @Input() applicationIdentifier!: string;
  @Input() sensors: any;
  @Input() ieeeSave!: boolean;
  @Input() isAdmin: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() selectedApplication: any;
  @Input() currentStepName!: string;
  @Input() applicationData: any; //application data for filter sensor with module name
  @Input() isSaveAndExitInitiated: boolean = false;
  @Output() onExitPressed = new EventEmitter();
  @Output() onUnsavedChanges = new EventEmitter();
  @Output() onSavePressed = new EventEmitter();
  @Output() onBackPressed = new EventEmitter();
  @Output() refreshApplication = new EventEmitter();
  @Output() circuitLoaded: EventEmitter<void> = new EventEmitter<void>();
  @Output() loader = new EventEmitter();


  konvaUtils!: KonvaUtils;
  isNewWorkspace: boolean = false;

  constructor(
    private store: Store<IAppState>,
    private applicationTemplateService: ApplicationService,
    private ideService: IDEService,
    private toasterService: ToastrService,
    private commonActions: CommonActions,
    private userSessionService: UserSessionService,
    private sessionStorageService: SessionStorageService
  ) { }

  async ngOnInit() {
    localStorage.clear();
      this.getApplicationTempV3(this.applicationIdentifier);
    this.loadExistingOnWorkspace();
    this.circuitLoaded.emit();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if(this.ieeeSave){
      await this.save()
    }
    if (!this.konvaUtils) {
      this.konvaUtils = new KonvaUtils(this.store, this.applicationIdentifier, `${CreateCircuitComponent.name}-${this.ngOnChanges.name}`);
    }
    const isSaveAndExitInitiated = changes?.isSaveAndExitInitiated?.currentValue;

    if (isSaveAndExitInitiated === true) {
      this.onSaveAndExit();
    }
    this.loadExistingOnWorkspace();

  }

  private async getApplicationTempV3(application_identifier: string) {
    let data: any;
    try {
        data = await this.applicationTemplateService.getApplicationTemplateV3(
          application_identifier
        );
      if (data?.Application) {
        this.selectedApplication = data.Application;
      }
    } catch (error) {
      console.log(error);
    }
  }

  private loadExistingOnWorkspace(): void {
    const { configurationDefinition } = this.selectedApplication;
    if (
      configurationDefinition === "" ||
      configurationDefinition === undefined ||
      configurationDefinition === null
    ) {
      this.isNewWorkspace = true;
      return;
    }
    const circuitDefinition = JSON.parse(configurationDefinition)?.circuitDefinition;
    if (circuitDefinition === undefined) {
      this.isNewWorkspace = true;
      return;
    }
    const { targets } = circuitDefinition;
    if (this.commonActions.isEmpty(configurationDefinition && targets)) {
      this.isNewWorkspace = true;
      return;
    }
    try {
      this.konvaUtils.loadExisting(
        circuitDefinition,
        CircuitLoadOptions.WRITABLE,
        CreateCircuitComponent.name
      );
    } catch (e: any) {
      this.konvaUtils.loadExisting(
        JSON.parse(e.message as string),
        CircuitLoadOptions.WRITABLE,
        `${CreateCircuitComponent.name}-catch`
      );
    }
  }

  private getCircuitData(): any {
    let targets: any, wires: any, circuitJsonData: any;
    this.store.select("circuitDesign").subscribe((data) => {
      targets = data.targets;
      wires = data.wires;
    });
    circuitJsonData = this.konvaUtils.save();
    circuitJsonData = Object.assign({ targets, }, { wires, }, circuitJsonData);
    return circuitJsonData;
  }

  async getS3URL() {
    // console.log("data in circuit ",this.selectedApplication)
    const payload = {
      configurationDefinition: true
    }
    let s3_urls: any;
    if(this.ieeeEvent){
      s3_urls = await this.applicationTemplateService.updateAppTemplateV2(payload, this.applicationIdentifier).toPromise();
    }else{
      if (this.isAdmin) {
        s3_urls = await this.applicationTemplateService.updateAppTemplateV2(payload, this.applicationIdentifier).toPromise();
      } else {
        s3_urls = await this.applicationTemplateService.updateApplicationV2(payload, this.applicationIdentifier).toPromise();
      }
    }
    return s3_urls;
  }

  async save(): Promise<any> {
    this.loader.emit(true);
    let s3_urls: any = await this.getS3URL();
    const circuitDefinition = this.getCircuitData();
    const configurationDefinition = {
      circuitDefinition,
      sensors: this.sensors.ids
    };
    if (s3_urls?.data?.configurationDefinition) {
      const configString = JSON.stringify(configurationDefinition).replace(/(http:\/\/localhost:4200\/)/g, environment.domainUrl);
      const blob = new Blob([configString], {
        type: "application/json",
      });
      const file = new File([blob], "circuit.json", {
        type: "application/json",
      });
      this.toasterService.warning("Saving circuit data...");
      const result = await this.ideService.uploadToS3(file, s3_urls.data.configurationDefinition).toPromise()
        .then(() => {
          if (!this.selectedApplication) {
            this.selectedApplication = {};
          }
          this.selectedApplication.configurationDefinition = configString;
          this.toasterService.success("Circuit saved successfully");
          this.loader.emit(false);
          return s3_urls;
        })
        .catch((err) => {
          console.log(err);
          this.toasterService.error("Could not save circuit data.");
          this.loader.emit(false);
          return;
        });
      return result;
    }
    else {
      this.toasterService.error("Upload storage is unavailable at the moment. Please try again later.");
      this.loader.emit(false);
      return;
    }
  }

  async onNext() {
    try {
      let s3_urls_updated = await this.save();
      if (!!s3_urls_updated) {
        this.onSavePressed.emit(s3_urls_updated);
        this.refreshApplication.emit()
      }
    } catch (err) {
      console.log(err);
    }
  }

  goBack() {
    this.onBackPressed.emit();
  }

  onExit() {
    let isUnsavedChangesPresent = this.checkUnsavedChanges();

    this.onUnsavedChanges.emit(isUnsavedChangesPresent);
    this.onExitPressed.emit({});
  }

  checkUnsavedChanges(): boolean {
    if (!this.selectedApplication?.configurationDefinition) {
      return true;
    }

    const { configurationDefinition } = this.selectedApplication;
    let previousCircuitDefinition: circuitDefinition = JSON.parse(configurationDefinition)?.circuitDefinition;
    previousCircuitDefinition = JSON.parse(JSON.stringify(previousCircuitDefinition).replace(/(http:\/\/localhost:4200\/)/g, environment.domainUrl));

    let currentCircuitDefinition: circuitDefinition = this.getCircuitData();
    currentCircuitDefinition = JSON.parse(JSON.stringify(currentCircuitDefinition).replace(/(http:\/\/localhost:4200\/)/g, environment.domainUrl));

    if (
      JSON.stringify(previousCircuitDefinition.wires) != JSON.stringify(currentCircuitDefinition.wires) ||
      JSON.stringify(previousCircuitDefinition.targets) != JSON.stringify(currentCircuitDefinition.targets)
    ) {
      return true;
    } else {
      return false;
    }
  }

  async onSaveAndExit() {
    await this.save();
    await this.onExit();
  }
}

@NgModule({
  declarations: [
    CreateCircuitComponent
  ],
  imports: [
    CommonModule,
    CircuitSectionModule
  ],
  exports: [
    CreateCircuitComponent
  ],
  providers: [],
})
export class CreateCircuitModule { }
